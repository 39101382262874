.corporatewellness{

    &>.row>.splash{
        background-image: url(../assets/images/corporatewellness1.jpg);
        padding: 10rem 0;
        background-position:50% 60%;
    }

    h3{
        font-weight: 700;
    }

    h1,h4{
        color: white;
    }

    p{
        font-size: 1.3em;
    }

    i{
        color: #c26263;
        font-weight: 700;
    }

    .img-ovrd{
        max-width: 100% !important;
        height: auto;
    }

}
