body {
    font-family: 'Montserrat', sans-serif;
    color:#2e3d50;
    background-color: #efefef;
    h1,h2,h3,h4,h5{
        font-weight: 400;
    }
}

[class*="sect"]{
    h3{
        text-align: center;
    }

    ul{
        list-style-type: none;
    }

    li{
        font-size: 1.2em;
        margin-top: 10px;

        &>.icon-checkmark{
            margin-right: 1rem;
        }
    }

    img{
        max-width: 500px;
        width: 100%;
        height: auto;
        border: 1px solid #ccc;
    }
}

[class*="sect-alter"]{
    background-color:#dfdfdf;
}

@media only screen and (min-width: 768px){
    [class*="sect"]{
        h3{
            text-align: left;
        }
    }
}