.splash{
    background-size: cover;
    background-position:50% 50%;
    border-bottom: 1px solid #ccc;
    }

.o-divider{
    background-color: #414d5e;
    color: #414d5e;
    border-radius: 100px;
    height: 2px;
    text-align: center;
}

.o-div-white{
    background-color: white;
}

.btn-secondary{
    background-color:white;
    border:white;
    color: #414d5e;
}

.home1{

    .splash{
        
        h2{
            padding-top: 12rem;
            color: white;
            text-align: center;
            font-size: 1.5rem;
        }

        p{
            margin-bottom: 12rem;
            &:hover{
                cursor: pointer;
            }            
        }
    }

    .splash1{
        background-image: url(../assets/images/oldhamstrongsplash.png);
    }

    .splash2{
        background-image: url(../assets/images/oldhampower2.jpg);
        background-position:50% 40%;
    }

    .splash3{
        background-image: url(../assets/images/nutritionplan.jpg);
        background-position:50% 20%;
    }

    .splash35{
        background-image: url(../assets/images/onlinecompetitorcoaching.jpg);
        background-position:50% 50%;
    }

    .splash4{
        background-image: url(../assets/images/corporatewellnesschattanooga.jpg);
    }

    .splash45{
        background-image: url(../assets/images/inpersontraining.jpg);
    }

    .splash5{
        background-image: url(../assets/images/theabundanceformula2.jpg);
        background-position:50% 95%;
    }

    .splash6{
        background-image: url(../assets/images/blog.jpg);
    }

    .splash7{
        background-image: url(../assets/images/oldhamstrongevents.jpg);
    }

    h4{
        line-height: 1.5;
    }

    a{
            border: 1px solid white;
            padding:1rem;
            background-color: transparent;
            color: white;
            text-decoration: none;
            &:hover{
                background-color:white;
                color: #414d5e;
                cursor: pointer;
                text-decoration: none;
            }
        }

}