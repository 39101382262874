.carousel{
    overflow-x: hidden;
    position: relative;

    &>.c-bg{
        background-image: url(../assets/images/carouselbg.jpg);
        background-size:contain;
        //padding-top:15rem;
        height: 70rem;
    }

    p{
        color: white;
    }

    button{
        &.pre{
            position: absolute;
            left: 5px;
            top: 50%;
        }

        &.next{
            position: absolute;
            right: 5px;
            top: 50%;
        }

        i{
            font-size: 1.7em;
            color: white;
            &:hover{
                color: #97bff8;
            }
        }
    }

    .slide{
        position: absolute;
        width: 100%;
        transition: all 300ms;
        display: none;
        margin: auto;
        height: fit-content;
        height:-moz-fit-content;
        top: 0;
        left:0;
        bottom:0;
        right: 0;

        img{
            max-width: 75%;
            height: auto;
        }
    }

    .activeSlide{
        left:0% !important;
        display: block !important;
    }

    .preSlide{
        left:-200%;
        display: block !important;
    }

    .nextSlide{
        left: 100%;
        display: block !important;
    }


@media(min-width: 768px){
    &>.c-bg{
        //padding-top:20rem;
        height: 45rem;
    }
}


}
