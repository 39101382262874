.onlinetraining1{

    &>.row>.splash{
        background-image: url(../assets/images/onlinetraining.jpg);
        padding: 10rem 0;
        background-position:50% 20%;
    }

    h3{
        font-weight: 700;
    }

    h1,h4{
        color: white;
    }

    p{
        font-size: 1.3em;
    }

    i{
        color: #c26263;
        font-weight: 700;
    }

}
