@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?dn0bm2');
  src:  url('fonts/icomoon.eot?dn0bm2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?dn0bm2') format('truetype'),
    url('fonts/icomoon.woff?dn0bm2') format('woff'),
    url('fonts/icomoon.svg?dn0bm2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\ea10";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
