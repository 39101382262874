.podcast{
    background-image: url(../assets/images/theabundanceformula.jpg);
    background-size: cover;
    background-position:10% 25%;
    border-bottom: 1px solid #ccc;
    padding-bottom:5rem;

    .title-bar{
        margin-top: 35rem;
        background-color: rgba(255,255,255,.70);

        h1, h4{
            margin-bottom: 0;
        }

        h1{
            font-family: 'Shadows Into Light Two', cursive;
        }

        a{
            background-color: #2e3d50;
            color: white;
            padding: 1em;
            transition: all 300ms;
            &:hover{
                background-color: #ffebee; 
                color: #2e3d50;
                cursor: pointer;
                text-decoration: none;
            }
        }
    }

}

.episodelink{
        
    background-color: #2e3d50;
    color: white;
    padding: 1em;
    transition: all 300ms;
    &:hover{
        background-color: #ffebee; 
        color: #2e3d50;
        cursor: pointer;
        text-decoration: none;
    }

}

    
