.nutritionplan1{

    &>.row>.splash{
        background-image: url(../assets/images/nutritionplan1.jpg);
        padding: 10rem 0;
        background-position:50% 20%;
    }

    h3{
        font-weight: 700;
    }

    h1,h4{
        color: white;
    }

    p{
        font-size: 1.3em;
    }

    i{
        color: #c26263;
        font-weight: 700;
    }

    [class*="sect"]{
        h3{
            text-align: center;
        }

        ul{
            list-style-type: none;
        }

        li{
            font-size: 1.2em;
            margin-top: 10px;

            &>.icon-checkmark{
                margin-right: 1rem;
            }
        }

        img{
            max-width: 500px;
            width: 100%;
            height: auto;
            border: 1px solid #ccc;
        }
    }

    .sect-3{
        background-color:#dfdfdf;
    }


}