.tafevent1{

    &>.row>.splash{
        background-image: url(../assets/images/theabundanceformulaevent1.jpg);
        padding: 5rem 0;
        background-position:50% 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #7a7a7a;
    }

    h3{
        font-weight: 700;
    }

    h1,h4{
        color: white;
    }

    p{
        font-size: 1.3em;
    }

    i{
        color: #db77b0;
        font-weight: 700;
    }

    strong{
        color: #db77b0;
    }

    [class*="sect"]{

        ul{
            list-style-type: none;
        }

        li{
            font-size: 1.2em;
            margin-top: 10px;

            &>.icon-checkmark{
                margin-right: 1rem;
            }
        }

        h3{
            color: #db77b0;
        }

        img{
            max-width: 500px;
            width: 100%;
            height: auto;
            border: 1px solid #ccc;
        }
    }

    .sect-3{
        background-color:#dfdfdf;
    }

    .sponsors{
        background-color: white;
        
    }

}